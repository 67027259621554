var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-card',{staticStyle:{"background-color":"teal"},attrs:{"flat":""}},[_c('v-card-text',{staticStyle:{"color":"white"}},[_vm._v("Student Information")])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"white--text",attrs:{"color":"#033"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"my-auto",attrs:{"xs12":"","sm12":"","md4":""}},[_c('v-img',{attrs:{"src":require("../../../assets/driver.svg"),"height":"80px","contain":""}})],1),_c('v-flex',{attrs:{"xs6":"","sm6":"","md3":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" Name ")]),_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" Parent Name ")]),_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" Mobile Number ")]),_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" Email ")])])])],1),_c('v-flex',{attrs:{"xs6":"","sm6":"","md3":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" "+_vm._s(_vm.studentname)+" ")]),_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" "+_vm._s(_vm.parentname)+" ")]),_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" "+_vm._s(_vm.parentmobile)+" ")]),_c('div',{class:{
                  caption: _vm.$vuetify.breakpoint.smAndDown,
                  'body-2': _vm.$vuetify.breakpoint.mdAndUp
                }},[_vm._v(" "+_vm._s(_vm.studentemail)+" ")])])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }