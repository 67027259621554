<script>
export default {
  name: "studentInfo",
  props: ["sdata","vdata"],
  data() {
    return {
      studentname: null,
      parentname: null,
      parentmobile: null,
      studentemail: null
    };
  },
  beforeMount() {
    this.studentname = this.sdata.studentName;
    this.parentname = this.sdata.parentName;
    this.parentmobile = this.sdata.parentMobile;
    this.studentemail = this.sdata.studentEmail;
  },
  mounted() {
    this.studentname = this.sdata.studentName;
    this.parentname = this.sdata.parentName;
    this.parentmobile = this.sdata.parentMobile;
    this.studentemail = this.sdata.studentEmail;
  }
};
</script>
<template>
  <div>
    <div>
      <v-card flat style="background-color:teal">
        <v-card-text style="color: white;">Student Information</v-card-text>
      </v-card>
      <!-- <v-row align="center" justify="center">
        <v-col cols="12">
          <v-toolbar-title class="white--text">
            Student Information
          </v-toolbar-title>
        </v-col>
      </v-row> -->
    </div>

    <v-flex xs12>
      <v-card color="#033" class="white--text">
        <v-layout row>
          <v-flex xs12 sm12 md4 class="my-auto">
            <v-img
              src="../../../assets/driver.svg"
              height="80px"
              contain
            ></v-img>
          </v-flex>
          <v-flex xs6 sm6 md3>
            <v-card-title primary-title>
              <div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Name
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Parent Name
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Mobile Number
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  Email
                </div>
              </div>
            </v-card-title>
          </v-flex>
          <v-flex xs6 sm6 md3>
            <v-card-title primary-title>
              <div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ studentname }}
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ parentname }}
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ parentmobile }}
                </div>
                <div
                  :class="{
                    caption: $vuetify.breakpoint.smAndDown,
                    'body-2': $vuetify.breakpoint.mdAndUp
                  }"
                >
                  {{ studentemail }}
                </div>
              </div>
            </v-card-title>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.white--text {
  opacity: 0.7;
}
td {
  text-align: center;
}
.v-card__text {
  padding: 0px !important;
  width: 100%;
}
img {
  display: flex;
  margin: 0 auto;
  padding: 6px;
}

.card__text {
  text-align: center !important;
}
</style>
